import React from 'react'

function Main() {
    return (
        <div className='main-container'>
            <div className='card'><h1>Hello, I'm Fu Fei. Nice to meet you. 👋</h1></div>
            <div className='time-box'>
                <div>[Full-time] Product Manager at Detrack</div>
                <div>[Part-time] Freelance Product Consultant</div>
                <div>[When-I-find-time] Blog and Side Projects</div>
            </div>
            <div className='para'>
                I love tinkering with new products so I'm incredibly lucky that I get to work full-time as a Product Manager. 
                During my free time, I code and blog (depending on my energy levels 😂) and from time-to-time, I do some 
                remote freelance work. If you have anything interesting projects you want to work on together, hit me up.
            </div>
            <div className='margin-top'>
                <h4>Life Hacks for you: 🔥🔥</h4>
                <ol>
                    <li><a href='https://www.notion.so/' className='underline'>Notion</a> to level up your personal productivity (it's free) </li>
                    <li><a href='https://www.animelon.com/' className='underline'>AnimeMelon</a>: Learn Japanese whilst watching Anime</li>
                    <li>Use Video Speed Controller to speed up YouTube <a className='underline' href='https://chrome.google.com/webstore/detail/video-speed-controller/nffaoalbilbmmfgbnbgppjihopabppdk?hl=en'>(ChromeStore)</a></li>
                    <li>Hate group networking? Try Lunchclub. Here is my <a href='https://lunchclub.com/?invite_code=feif' className='underline'>invite link.</a></li>
                    <li>Need help with product stuff? Here is my <a href='https://superpeer.com/symbolofflight' className='underline'>Superpeer link.</a></li>
                </ol>
            </div>
            <div className='current'>
                <div>
                    <h4>Current Side Projects:</h4>
                    <ul>
                        <li>Design Productivity App (coming soon)</li>
                        <li>Personal Wedding Site (Done)</li>
                        <li>Blog (<a href='https://p-zero.fufei.io/' className='underline'>Medium</a>)</li>
                    </ul>
                </div>
                <div>
                    <h4>Currently Learning:</h4>
                    <ul>
                        <li>Japanese 🇯🇵</li>
                        <li>Hindi 🇮🇳</li>
                    </ul>
                </div>
            </div>
            <hr/>
            <div className=''>

                <h3>Tool Belt:</h3>
                <div className='tool-belt'>
                    <div>
                        <h4>Tech Stuff</h4>
                        <ul>
                            <li><b>Front-end</b>: HTML, CSS, React.js, Next.JS</li>
                            <li><b>Back-end</b>: Node.js, Python, SQL, Supabase</li>
                            <li><b>Relevant Tools</b>: Stripe, SendGrid, DigitalOcean</li>
                        </ul>
                    </div>
                    <div>
                        <h4>Tools</h4>
                        <ul>
                            <li>MS Suite, GSuite </li>
                            <li>Slack, Jira, Trello</li>
                            <li>Sketch, Figma, Invision</li>
                            <li>Google Analytics, Mixpanel, Segment</li>
                            <li>Hubspot, ActiveCampaign</li>
                        </ul>
                    </div>
                    <div>
                        <h4>Certifications</h4>
                        <ul>
                            <li>Inbound Marketing (Hubspot)</li>
                            <li>Structuring ML Projects (Coursera)</li>
                            <li>Neural Networks and Deep Learning (Coursera)</li>
                            <li>The Complete Web Developer: Zero to Mastery (Udemy)</li>
                        </ul>
                    </div>
                </div>
            </div>
            <hr/>
            <div className='recent-work'>
                <h3>Education:</h3>
                <div>SMU — Business, 2018 (Strategy) (Cum Laude)</div>
                <h3>Recent Product Work:</h3>
                <div>
                    <h4>Product Manager | Aspire (2022-Present)</h4>
                    <ul>
                        <li><b>Domains</b>: Logistics (Vehicle Tracking, POD) </li>
                        <li>WIP</li>
                    </ul>
                </div>
                <div>
                    <h4>Product Manager | Aspire (2021-2022)</h4>
                    <ul>
                        <li><b>Domains</b>: Fintech, Growth PM</li>
                        <li>Growth inititative to improve onboarding</li>
                        <li>Reduced complexity in onboarding for new customers</li>
                    </ul>
                </div>
                <div>
                    <h4>Product Manager | Toptal (2021~)[Part-Time]</h4>
                    <ul>
                        <li><b>Domains</b>: HR APIs, Video Storage & Delivery</li>
                        <li>Setting up data and metrics infrastructure</li>
                        <li>JTBD presentations, Structuring User Interviews</li>
                    </ul>
                </div>
                <div>
                    <h4>Product Manager | Ninja Van (2020-2021)</h4>
                    <ul>
                        <li><b>Domains</b>: First-Mile Logistics, B2B Dashboards</li>
                        <li>Product Manager for Merchant Dashboard (250k MAU)</li>
                        <li>Working with local country teams to consolidate regional product requirements</li>
                    </ul>
                </div>
                <div>
                    <h4>Product Manager | B1G1 (2020)</h4>
                    <ul>
                        <li><b>Domains</b>: Marketplaces, SaaS, Non-Profit Operations</li>
                        <li>Led efforts to set-up initial customer data platform (Segment)</li>
                        <li>Set-up initial product analytics infrastructure (Mixpanel)</li>
                        <li>Set-up initial product management process to increase engineering visibility</li> 
                    </ul>
                </div>
                <div>
                    <h4>CoFounder | Haste (2017-2019)</h4>
                    <ul>
                        <li><b>Domains</b>: Marketing Tech, E-commerce Analytics, Payment Systems</li>
                        <li>Led product sales and marketing efforts, closed the first dozen accounts</li>
                        <li>Translated user research into minimal lovable features and experiments</li>
                    </ul>
                </div>
            </div>
            <hr/>
            <div className='recent-work'>
                <h3>Recent Articles</h3>
                <ul>
                    <li><a className='underline' href='https://p-zero.fufei.io/how-to-build-great-products-367059df3aa'>Building great products as founders</a></li>
                    <li><a className='underline' href='https://p-zero.fufei.io/what-makes-a-market-34934f029cc0'>What makes a market?</a></li>
                    <li><a className='underline' href='https://medium.com/biz-bytes/how-saas-is-unbundling-the-audience-economy-4bd4fbad1109'>How SaaS is unbundling the audience economy</a></li>
                    <li><a className='underline' href='https://medium.com/biz-bytes/how-tiktok-built-a-better-product-e9a48dade5c5'>How TikTok built a better product</a></li>
                    <li><a className='underline' href='https://medium.com/better-marketing/how-to-build-a-marketing-state-machine-to-optimize-your-funnels-16137975b5c4'>How to build a marketing state machine</a></li>
                </ul>
            </div>
            <hr/>
        </div>
    )
}
export default Main;
