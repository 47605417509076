import './App.css';
import Main from './components/Main/Main';
import Navbar from './components/Navbar/Navbar';
import {Helmet} from "react-helmet";

function App() {
  return (
    <div className="app" >
      <Helmet>
          <meta charSet="utf-8" />
          <title>Hello! I'm Fu Fei</title>
          <meta name="description" content="Nice to meet you! Thanks for taking the time to check this out"/>
      </Helmet>
      <Navbar />
      <Main/>
    </div>
  );
}

export default App;
