import React from 'react'
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faLinkedin, faTwitter} from '@fortawesome/free-brands-svg-icons';


function Navbar() {
    
    return (
        <nav style={{backgroundImage: "url(/assets/bg.png)", backgroundRepeat: 'no-repeat', backgroundPosition: "left top", backgroundSize: 'cover',filter: "grayscale(20%)"}}>
            <div className='nav-container'>
                <div>
                    <img className='profile-pic' src='./assets/profilePic.png' alt='fu fei'/>
                    <div className='nav-words'>
                        I love building cool new <br/> products.
                    </div>
                    <div className='nav-words-s'>
                       <b>About me:</b><br/>
                       #ProductGuy #Otaku #BasketballFan #Builder #TechNerd #Curious
                       #SashimiLover #ProductivityJunkie #CoffeeLover #DesignEnthusiast #Blogger
                       #SaaSNerd #Marketplace #WorkInProgress #Learner #PodcastLover 
                       #Runner #FinanceNewsJunkie #Dota2Fan #WhiskeyDrinker #Coder4Fun 
                    </div>
                </div>
                <div className='get-in-touch'>
                    Get in touch <br/>
                    <a href='mailto: hi@fufei.io'><FontAwesomeIcon icon={faEnvelope} /></a>
                    <a href='https://www.linkedin.com/in/fu-fei/'><FontAwesomeIcon icon={faLinkedin} /></a>
                    <a href='https://twitter.com/SymbolOfFlight'><FontAwesomeIcon icon={faTwitter} /></a>
                </div>
            </div>
            
        </nav>
        
    )
    
}
export default Navbar;
